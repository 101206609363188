import cl from './RecordPage.module.css'
import HeaderUniversal from '../../components/universalComponents/headerUniversal/HeaderUniversal'
import { useParams, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import recordStore from '../../store/RecordStore'
import RecordStatusSelect from '../../components/recordPage/recordStatusSelect/RecordStatusSelect'
import ServicesAndPrice from '../../components/recordPage/servicesAndPrice/ServicesAndPrice'
import RecordDate from '../../components/recordPage/recordDate/RecordDate'
import RecordComment from '../../components/recordPage/recordComment/RecordComment'
import CancelRecordButton from '../../components/recordPage/cancelRecordButton/CancelRecordButton'
import RecordPhoneNumber from '../../components/recordPage/recordPhoneNumber/RecordPhoneNumber'

const RecordPage = () => {
    const { id } = useParams()
    const tg = window.Telegram.WebApp;
    const navigate = useNavigate();

    useEffect(() => {
        if (recordStore.recordData.length === 0) {
            navigate('*');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [record, setRecord] = useState(recordStore.recordData.find(record => record.id === parseInt(id)));

    useEffect(() => {
        if (!tg.BackButton.isVisible) tg.BackButton.show();
        const handleBackButtonClick = () => {
            navigate('*');
        }

        tg.BackButton.onClick(handleBackButtonClick);

        return () => {
            tg.BackButton.offClick(handleBackButtonClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <HeaderUniversal>Запись</HeaderUniversal>

            <RecordStatusSelect record={record} />

            <RecordPhoneNumber phoneNumber={record.phoneNumber} />

            <ServicesAndPrice services={record.services} />

            <RecordDate date={record.date} duration={record.duration} />

            {record.comment &&
                <RecordComment comment={record.comment} />
            }

            {record.status !== 'CANCELED' &&
                <CancelRecordButton id={id}/>
            }
            
        </div>
    )
}

export default RecordPage
import cl from './CommentForCancel.module.css'
import { Footnote } from '../../../textStyles/TextStyleComponents';
import validationStore from '../../../store/ValidationStore';

const CommentForCancel = ({cancelComment, setCancelComment}) => {
    // const [value, setValue] = useState('');

    // const handleChange = (event) => {
    //     setValue(event.target.value);
    // };

    return (
        <div>
            <input
                className={cl.inpt}
                placeholder={"Комментарий клиенту"}
                value={cancelComment}
                onChange={(e) => validationStore.handleReguralInputChange(e, setCancelComment)}
            />
            <Footnote className={cl.footnote}>Отмененную запись нельзя вернуть</Footnote>
        </div>
    )
}

export default CommentForCancel
import { Body, HeadLineMedium } from '../../../textStyles/TextStyleComponents';
import cl from './ServicesAndPrice.module.css'

const ServicesAndPrice = ({ services }) => {
    const total = services.reduce((sum, service) => sum + service.price, 0);

    return (
        <div className={cl.container}>
            {services.length === 1 ? (
                <div className={cl.singleServiceContainer}>
                    <Body >{services[0].name}</Body>
                    <Body >{services[0].price}&nbsp;₽</Body>
                </div>
            ) : (
                <div className={cl.severalServicesContainer}>
                    {services.map((service, index) => (
                        <div key={index} className={cl.serviceItem}>
                            <Body className={cl.serviceName}>{service.name}</Body>
                            <Body className={cl.servicePrice}>{service.price}&nbsp;₽</Body>
                        </div>
                    ))}
                    <div className={cl.total}>
                        <HeadLineMedium>Итого</HeadLineMedium>
                        <HeadLineMedium>{total} ₽</HeadLineMedium>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ServicesAndPrice
import { Body, HeadLineMedium, Subheadline2 } from '../../../textStyles/TextStyleComponents'
import cl from './RecordStatusSelect.module.css'
import { ReactComponent as RecordStatusSelectIcon } from "../../../icons/RecordStatusSelect.svg"
import { useState, useRef, useEffect } from 'react'
import recordStore from '../../../store/RecordStore'
import { observer } from 'mobx-react-lite'
import axios from 'axios'
import ModalUniversal from '../../universalComponents/modalUniversal/ModalUniversal'

const RecordStatusSelect = observer(({ record }) => {
    const tg = window.Telegram.WebApp;

    const dropdownBorderStyleClass = tg.colorScheme === 'dark' ? cl.darkBorder : cl.lightBorder;
    const dropdownItemStyleClass = tg.colorScheme === 'dark' ? cl.darkDropdownItem : cl.lightDropdownItem;

    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);

    const dropdownRef = useRef(null);

    const getStatusText = (status) => {
        switch (status) {
            case 'CANCELED':
                return 'Отменена';
            case 'WAITING':
                return 'Ожидание';
            case 'COMPLETED':
                return 'Завершена';
            default:
                return '';
        }
    };

    const getStatusClass = (status) => {
        switch (status) {
            case 'CANCELED':
                return cl.canceled;
            case 'WAITING':
                return cl.waiting;
            case 'COMPLETED':
                return cl.completed;
            default:
                return '';
        }
    };

    const toggleDropdown = () => {
        if (record.status !== 'CANCELED') {
            setDropdownVisible(!isDropdownVisible);
        }
    };

    const handleStatusChange = async (newStatus) => {
        const oldStatus = record.status; // чтоб статус сразу менялся, не дожидаясь патч запроса, а в случае ошибки откатывался на изначальное значение
        record.status = newStatus;

        setDropdownVisible(false);

        const botUsername = localStorage.getItem('botUsername')
        const patchData = {
            status: newStatus,
        };

        try {
            const response = await axios.patch(`${process.env.REACT_APP_MAIN_API_URL}self-employed/${botUsername}/recordings/${record.id}`, patchData);
            console.log("Статус записи успешно обновлен", response.data);
        } catch (error) {
            console.error('Ошибка обновления статуса записи', error);
            record.status = oldStatus;
            setShowErrorModal(true);
        }
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !event.target.closest(`.${cl.selectContainer}`)) {
            setDropdownVisible(false);
        }
    };

    useEffect(() => {
        if (isDropdownVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdownVisible]);

    return (
        <div className={cl.container}>
            <div className={cl.selectContainer} onClick={toggleDropdown}>
                <div>
                    <HeadLineMedium>{record.name}</HeadLineMedium>
                    <Subheadline2 className={cl.phoneNumber}>{record.phone_number}</Subheadline2>
                </div>
                <div className={cl.recordStatusContainer}>
                    <Body className={getStatusClass(record.status)}>{getStatusText(record.status)}</Body>
                    {record.status !== 'CANCELED' && (
                        <RecordStatusSelectIcon className={cl.recordStatusSelectIcon} />
                    )}
                </div>
            </div>

            {isDropdownVisible && (
                <div ref={dropdownRef} className={`${cl.dropdown} ${dropdownBorderStyleClass}`}>

                    <div className={`${cl.dropdownItem} ${cl.completed} ${dropdownItemStyleClass}`} onClick={() => handleStatusChange('COMPLETED')}>
                        <Body>Завершена</Body>
                        <div className={cl.borderBottom}></div>
                    </div>

                    <div className={`${cl.dropdownItem} ${cl.waiting} ${dropdownItemStyleClass}`} onClick={() => handleStatusChange('WAITING')}>
                        <Body>Ожидание</Body>
                    </div>
                    {/* <div className={`${cl.dropdownItem} ${cl.canceled}`} onClick={() => handleStatusChange('CANCELED')}>                        
                        <Body>Отменена</Body>
                    </div> */}
                </div>
            )}

            {showErrorModal &&
                <ModalUniversal
                    text={'Не удалось изменить статус.\nПопробуйте снова'}
                    setIsVisible={setShowErrorModal}
                    cancelText={'Окей'}
                />
            }
        </div>
    )
})

export default RecordStatusSelect
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RecordPhoneNumber_container__9EU3I {
    height: 60px;
    margin-bottom: 10px;
    border-radius: 10px;
    background-color: var(--tg-theme-bg-color);

    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/recordPage/recordPhoneNumber/RecordPhoneNumber.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mBAAmB;IACnB,mBAAmB;IACnB,0CAA0C;;IAE1C,aAAa;IACb,8BAA8B;IAC9B,eAAe;IACf,mBAAmB;AACvB","sourcesContent":[".container {\n    height: 60px;\n    margin-bottom: 10px;\n    border-radius: 10px;\n    background-color: var(--tg-theme-bg-color);\n\n    display: flex;\n    justify-content: space-between;\n    padding: 0 16px;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RecordPhoneNumber_container__9EU3I`
};
export default ___CSS_LOADER_EXPORT___;

import { makeObservable, observable, action } from 'mobx';
import axios from 'axios';
// import recordForMyJson from "../Record.json";

class RecordStore {
    // recordData = recordForMyJson;
    recordData = [];

    constructor() {
        makeObservable(this, {
            recordData: observable,
            setRecordData: action
            // updateRecordStatus: action,
        }, { deep: true });
    }

    setRecordData = (newData) => {
        this.recordData = newData;
    }


    // updateRecordStatus = (id, newStatus) => {
    //     const record = this.recordData.find(record => record.id === parseInt(id));
    //     if (record) {
    //         record.status = newStatus;
    //     }
    // }

    // updateRecordStatus = async (id, newStatus, cancelComment = null) => {
    //     const botUsername = 'wehfbwkeffhewlkfketetris_bot';

    //     const navigate = useNavigate();

    //     const patchData = {
    //         status: newStatus,
    //     };

    //     console.log('КОММЕНТ', cancelComment)

    //     // // Добавляем cancelComment только если он передан
    //     if (cancelComment !== null) {
    //         console.log('Сработало условие')
    //         patchData.actor = 'CLIENT';
    //         patchData.cancelComment = cancelComment;
    //     }

    //     try {
    //         const response = await axios.patch(`https://test-api.gros.pro/api/self-employed/${botUsername}/recordings/${id}`, patchData);
    //         console.log("Статус записи успешно обновлен", response.data);
    //         navigate('*');
    //     } catch (error) {
    //         console.error('Ошибка обновления статуса записи', error);
    //     }
    // }


}

const recordStore = new RecordStore();
export default recordStore;

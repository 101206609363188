import { makeObservable, observable, action } from 'mobx';

class SelectedDateStore {
    selectedDate = {};

    constructor() {
        makeObservable(this, {            
            selectedDate: observable,
            setSelectedDate: action
        }, {deep: true, });
    }

    setSelectedDate(date) {
        this.selectedDate = date;
      }
}

const selectedDateStore = new SelectedDateStore();

export default selectedDateStore;
import { Caption2, HeadLineMedium, Subheadline2 } from "../../../../textStyles/TextStyleComponents";
import cl from "./RecordItem.module.css"
import { ReactComponent as CommentIcon } from '../../../../icons/Comment.svg';
import { useNavigate } from "react-router-dom";
import RecordSeparator from "../../../universalComponents/recordSeparator/RecordSeparator";

const RecordItem = ({ record, nextRecord, isCanceled }) => {
    const tg = window.Telegram.WebApp;
    const navigate = useNavigate();
    const serviceCount = record.services.length;

    // Массив символов Unicode для чисел от 1 до 9
    const unicodeNumbers = ['\u2460', '\u2461', '\u2462', '\u2463', '\u2464', '\u2465', '\u2466', '\u2467', '\u2468'];

    const startTime = record.date.split(' ')[1];
    const startTimeOfNextRecord = nextRecord && nextRecord.date.split(' ')[1];
    const durationMinutes = record.duration;

    // Преобразуем время начала в объект Date
    const startDateTime = new Date(`2000-01-01T${startTime}:00`);

    // Вычисляем время окончания
    const endDateTime = new Date(startDateTime.getTime() + durationMinutes * 60000); // duration в минутах

    // Форматируем время окончания в формат HH:mm
    const endTime = endDateTime.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' });

    let timeToNextService = '';
    if (nextRecord) {
        const nextStartTime = nextRecord.date.split(' ')[1];
        const nextStartDateTime = new Date(`2000-01-01T${nextStartTime}:00`);
        const diffMs = nextStartDateTime - endDateTime;
        const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
        const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
        if (diffHours > 0 || diffMinutes > 0) {
            timeToNextService = `${diffHours > 0 ? `${diffHours} час${diffHours > 1 ? 'а' : ''} ` : ''}${diffMinutes > 0 ? `${diffMinutes} минут` : ''}`;
        }
    }

    let statusClass = tg.colorScheme;
    switch (record.status) {
        case "COMPLETED":
            statusClass += "Completed";
            break;
        case "WAITING":
            statusClass += "Waiting";
            break;
        case "CANCELED":
            statusClass += "Canceled";
            break;
        default:
            statusClass = "";
    }

    const handleClickOnRecord = () => {
        navigate(`/recordPage/${record.id}`)
    }

    console.log("ЗАПИСЬ НАХУЙ")
    console.log(startTime)
    console.log(startTimeOfNextRecord)

    // const serviceNames = record.services.join(', ');
    const serviceNames = record.services.map(service => service.name).join(', ');

    return (
        <div className={cl.container}>
            <div className={`${cl.serviceContainer} ${cl[`${statusClass}Container`]}`} onClick={handleClickOnRecord}>
                <div className={cl.servicesNameContainer}>
                    <div className={cl.services}>
                        {serviceCount > 1 && serviceCount <= 9 && (
                            <HeadLineMedium className={`${cl.unicodeNumber} ${cl[`${statusClass}Text`]}`}>{unicodeNumbers[serviceCount - 1]}</HeadLineMedium>
                        )}
                        <HeadLineMedium className={`${cl.serviceList} ${cl[`${statusClass}Text`]}`}>{serviceNames}</HeadLineMedium>

                    </div>

                    <div className={cl.nameContainer}>
                        <Subheadline2 className={`${cl[`${statusClass}Text`]}`}>{record.name}</Subheadline2>
                        {record.comment && (
                            <CommentIcon className={`${cl.commentIcon} ${cl[`${statusClass}Icon`]}`} />
                        )}
                    </div>
                </div>
                <div className={cl.timeContainer}>
                    <HeadLineMedium className={`${cl.time} ${cl[`${statusClass}Text`]}`}>{startTime}</HeadLineMedium>
                    <Caption2 className={`${cl.time} ${cl[`${statusClass}HintText`]}`}>{`до ${endTime}`}</Caption2>
                </div>

            </div>
            {!isCanceled ?
                nextRecord &&
                    startTimeOfNextRecord !== endTime ?
                    <RecordSeparator text={`Перерыв ${timeToNextService}`} />
                    : <div className={cl.noneSeparator}></div>
                : <div className={cl.noneSeparator}></div>
            }
        </div>

    )
}

export default RecordItem
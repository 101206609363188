import cl from "./HeaderUniversal.module.css";
import { Title1 } from "../../../textStyles/TextStyleComponents";

const HeaderUniversal = ({ children }) => {

    return (
        <div className={cl.header}>
            <Title1>{children}</Title1>
        </div>
    )
}

export default HeaderUniversal;
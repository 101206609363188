import cl from "./RecordSeparator.module.css"
import { Caption2 } from "../../../textStyles/TextStyleComponents"

const RecordSeparator = ({ text }) => {


    return (
        <div className={cl.timeToNextServiceContainer}>
            <div className={cl.line}></div>
            <Caption2 className={cl.timeToNextService}>{text}</Caption2>
            <div className={cl.line}></div>
        </div>
    )
}

export default RecordSeparator
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RecordDate_container__16vJA {
    min-height: 60px;
    border-radius: 10px;
    background-color: var(--tg-theme-bg-color);
    margin-bottom: 10px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
}

.RecordDate_hintText__hGlge {
    color: var(--tg-theme-hint-color);
}`, "",{"version":3,"sources":["webpack://./src/components/recordPage/recordDate/RecordDate.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,0CAA0C;IAC1C,mBAAmB;;IAEnB,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,iCAAiC;AACrC","sourcesContent":[".container {\n    min-height: 60px;\n    border-radius: 10px;\n    background-color: var(--tg-theme-bg-color);\n    margin-bottom: 10px;\n\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding-left: 16px;\n    padding-right: 16px;\n}\n\n.hintText {\n    color: var(--tg-theme-hint-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RecordDate_container__16vJA`,
	"hintText": `RecordDate_hintText__hGlge`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HeaderUniversal_header__qzdxt {
    margin-top: 30px;
    margin-bottom: 32px;
}
`, "",{"version":3,"sources":["webpack://./src/components/universalComponents/headerUniversal/HeaderUniversal.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".header {\n    margin-top: 30px;\n    margin-bottom: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `HeaderUniversal_header__qzdxt`
};
export default ___CSS_LOADER_EXPORT___;

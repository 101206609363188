// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CancelRecordButton_container__xVnCk {
    margin-top: 20px;
    display: flex;
    background-color: rgba(255, 69, 48, 0.1);
    height: 32px;
    width: 140px;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
}

.CancelRecordButton_text__BbEvO {
    color: var(--tg-theme-destructive-text-color);
}`, "",{"version":3,"sources":["webpack://./src/components/recordPage/cancelRecordButton/CancelRecordButton.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,wCAAwC;IACxC,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,6CAA6C;AACjD","sourcesContent":[".container {\n    margin-top: 20px;\n    display: flex;\n    background-color: rgba(255, 69, 48, 0.1);\n    height: 32px;\n    width: 140px;\n    align-items: center;\n    justify-content: center;\n    border-radius: 40px;\n}\n\n.text {\n    color: var(--tg-theme-destructive-text-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CancelRecordButton_container__xVnCk`,
	"text": `CancelRecordButton_text__BbEvO`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RecordSeparator_timeToNextServiceContainer__pKiYV {
    margin: 12px 4px; 
    display: flex;
    justify-content: center;
    align-items: center;
}

.RecordSeparator_timeToNextService__Iv64K {
    color: var(--tg-theme-hint-color);
    margin: 0 6px;
}

.RecordSeparator_line__BdY4S {
    flex: 1 1;
    height: 1px;
    background-color: var(--tg-theme-hint-color);
}`, "",{"version":3,"sources":["webpack://./src/components/universalComponents/recordSeparator/RecordSeparator.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,iCAAiC;IACjC,aAAa;AACjB;;AAEA;IACI,SAAO;IACP,WAAW;IACX,4CAA4C;AAChD","sourcesContent":[".timeToNextServiceContainer {\n    margin: 12px 4px; \n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.timeToNextService {\n    color: var(--tg-theme-hint-color);\n    margin: 0 6px;\n}\n\n.line {\n    flex: 1;\n    height: 1px;\n    background-color: var(--tg-theme-hint-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timeToNextServiceContainer": `RecordSeparator_timeToNextServiceContainer__pKiYV`,
	"timeToNextService": `RecordSeparator_timeToNextService__Iv64K`,
	"line": `RecordSeparator_line__BdY4S`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ServicesAndPrice_container__YIDjx {
    min-height: 60px;
    border-radius: 10px;
    background-color: var(--tg-theme-bg-color);
    margin-bottom: 10px;
}

.ServicesAndPrice_singleServiceContainer__y63s6 {
    display: flex;
    justify-content: space-between;
    padding: 19px 16px;
}

.ServicesAndPrice_serviceItem__RUK03 {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    padding-bottom: 9px;
    color: var(--tg-theme-hint-color);
}

.ServicesAndPrice_total__XoZT7 {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
}

.ServicesAndPrice_severalServicesContainer__o01tX {
    padding-top: 14px;
    padding-bottom: 14px;
}`, "",{"version":3,"sources":["webpack://./src/components/recordPage/servicesAndPrice/ServicesAndPrice.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,0CAA0C;IAC1C,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,eAAe;IACf,mBAAmB;IACnB,iCAAiC;AACrC;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,oBAAoB;AACxB","sourcesContent":[".container {\n    min-height: 60px;\n    border-radius: 10px;\n    background-color: var(--tg-theme-bg-color);\n    margin-bottom: 10px;\n}\n\n.singleServiceContainer {\n    display: flex;\n    justify-content: space-between;\n    padding: 19px 16px;\n}\n\n.serviceItem {\n    display: flex;\n    justify-content: space-between;\n    padding: 0 16px;\n    padding-bottom: 9px;\n    color: var(--tg-theme-hint-color);\n}\n\n.total {\n    display: flex;\n    justify-content: space-between;\n    padding: 0 16px;\n}\n\n.severalServicesContainer {\n    padding-top: 14px;\n    padding-bottom: 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ServicesAndPrice_container__YIDjx`,
	"singleServiceContainer": `ServicesAndPrice_singleServiceContainer__y63s6`,
	"serviceItem": `ServicesAndPrice_serviceItem__RUK03`,
	"total": `ServicesAndPrice_total__XoZT7`,
	"severalServicesContainer": `ServicesAndPrice_severalServicesContainer__o01tX`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CommentForCancel_inpt__C1vGk {
    background-color: var(--tg-theme-bg-color);
    height: 44px;
    border: none;
    color: var(--tg-theme-text-color);
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.43px;
    font-family: 'SF Pro Regular';
    outline: none;
    border-radius: 10px;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    margin-bottom: 10px;
}

.CommentForCancel_inpt__C1vGk::placeholder {
    color: var(--tg-theme-hint-color);
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.43px;
    font-family: 'SF Pro Regular';
}

.CommentForCancel_footnote__Hl6oo {
    color: var(--tg-theme-hint-color);
    margin-left: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/cancelRecordPage/commentForCancel/CommentForCancel.module.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;IAC1C,YAAY;IACZ,YAAY;IACZ,iCAAiC;IACjC,eAAe;IACf,iBAAiB;IACjB,uBAAuB;IACvB,6BAA6B;IAC7B,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;IACnB,WAAW;IACX,sBAAsB;IACtB,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,iCAAiC;IACjC,eAAe;IACf,iBAAiB;IACjB,uBAAuB;IACvB,6BAA6B;AACjC;;AAEA;IACI,iCAAiC;IACjC,iBAAiB;AACrB","sourcesContent":[".inpt {\n    background-color: var(--tg-theme-bg-color);\n    height: 44px;\n    border: none;\n    color: var(--tg-theme-text-color);\n    font-size: 17px;\n    line-height: 22px;\n    letter-spacing: -0.43px;\n    font-family: 'SF Pro Regular';\n    outline: none;\n    border-radius: 10px;\n    padding-left: 16px;\n    padding-right: 16px;\n    width: 100%;\n    box-sizing: border-box;\n    max-width: 100%;\n    margin-bottom: 10px;\n}\n\n.inpt::placeholder {\n    color: var(--tg-theme-hint-color);\n    font-size: 17px;\n    line-height: 22px;\n    letter-spacing: -0.43px;\n    font-family: 'SF Pro Regular';\n}\n\n.footnote {\n    color: var(--tg-theme-hint-color);\n    margin-left: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inpt": `CommentForCancel_inpt__C1vGk`,
	"footnote": `CommentForCancel_footnote__Hl6oo`
};
export default ___CSS_LOADER_EXPORT___;

import Calendar from "../../components/recordListPage/calendar/Calendar"
// import cl from "./RecordListPage.module.css"
import RecordList from "../../components/recordListPage/recordList/RecordList";
import { useEffect } from "react";
import recordStore from "../../store/RecordStore";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const RecordListPage = () => {
    const tg = window.Telegram.WebApp;
    const navigate = useNavigate();

    useEffect(() => {
        const initData = tg.initData;

        const parseInitData = (initData) => {
            return Object.fromEntries(new URLSearchParams(initData));
        };

        const parsedData = parseInitData(initData);

        localStorage.setItem('botUsername', JSON.parse(parsedData.user).id);

        // localStorage.setItem('botUsername', 'wehfbwkeffhewlkfketetris_bot')

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        tg.BackButton.hide();

        tg.MainButton.enable();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const getRecordData = async () => {
            const botUsername = localStorage.getItem('botUsername')
            try {
                const response = await axios.get(`${process.env.REACT_APP_MAIN_API_URL}self-employed/${botUsername}/recordings`);
                recordStore.recordData = response.data;
                console.log("Загружены данные о записях");
            } catch (error) {
                console.error('Ошибка загрузки данных о записях', error);
                const errorCode = error.response?.status || 500;
                let errorMessage = error.response?.statusText || 'Internal Server Error';
                if (errorCode === 404) errorMessage = 'Сначала настройте профиль';
                navigate('/errorPage', { state: { errorCode, errorMessage } });
            }
        };

        getRecordData();

    }, []);

    return (
        <div>
            <Calendar />

            <RecordList />
        </div>
    )
}

export default RecordListPage
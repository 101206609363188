// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/SF-Pro-Display-Bold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/SF-Pro-Display-Regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./fonts/SF-Pro-Display-Semibold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./fonts/SF-Pro-Rounded-Semibold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./fonts/SF-Pro-Display-Medium.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./fonts/SF-Mono-Regular.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-color: var(--tg-theme-secondary-bg-color);
  color: var(--tg-theme-text-color);
  max-width: 400px;
  margin-left: 15px;
  margin-right: 15px;
}

@font-face {
  font-family: 'SF Pro Bold';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff2');;
}

@font-face {
  font-family: 'SF Pro Regular';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff2');;
}

@font-face {
  font-family: 'SF Pro Semibold';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff2');;
}

@font-face {
  font-family: 'SF Pro Rounded Semibold';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('woff2');;
}

@font-face {
  font-family: 'SF Pro Medium';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('woff2');;
}

@font-face {
  font-family: 'SF Mono Regular';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('woff2');;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,oDAAoD;EACpD,iCAAiC;EACjC,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,0BAA0B;EAC1B,4DAA2D;AAC7D;;AAEA;EACE,6BAA6B;EAC7B,4DAA8D;AAChE;;AAEA;EACE,8BAA8B;EAC9B,4DAA+D;AACjE;;AAEA;EACE,sCAAsC;EACtC,4DAA+D;AACjE;;AAEA;EACE,4BAA4B;EAC5B,4DAA6D;AAC/D;;AAEA;EACE,8BAA8B;EAC9B,4DAAuD;AACzD","sourcesContent":["body {\n  background-color: var(--tg-theme-secondary-bg-color);\n  color: var(--tg-theme-text-color);\n  max-width: 400px;\n  margin-left: 15px;\n  margin-right: 15px;\n}\n\n@font-face {\n  font-family: 'SF Pro Bold';\n  src: url('./fonts/SF-Pro-Display-Bold.otf') format('woff2');;\n}\n\n@font-face {\n  font-family: 'SF Pro Regular';\n  src: url('./fonts/SF-Pro-Display-Regular.otf') format('woff2');;\n}\n\n@font-face {\n  font-family: 'SF Pro Semibold';\n  src: url('./fonts/SF-Pro-Display-Semibold.otf') format('woff2');;\n}\n\n@font-face {\n  font-family: 'SF Pro Rounded Semibold';\n  src: url('./fonts/SF-Pro-Rounded-Semibold.otf') format('woff2');;\n}\n\n@font-face {\n  font-family: 'SF Pro Medium';\n  src: url('./fonts/SF-Pro-Display-Medium.otf') format('woff2');;\n}\n\n@font-face {\n  font-family: 'SF Mono Regular';\n  src: url('./fonts/SF-Mono-Regular.otf') format('woff2');;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

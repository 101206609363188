import { observer } from 'mobx-react-lite';
import cl from "./RecordList.module.css";
import recordStore from "../../../store/RecordStore";
import RecordItem from './recordItem/RecordItem';
import { Body } from '../../../textStyles/TextStyleComponents';
import RecordSeparator from '../../universalComponents/recordSeparator/RecordSeparator';
import selectedDateStore from '../../../store/SelectedDateStore';

const RecordList = observer(() => {
    const { day, month, year } = selectedDateStore.selectedDate;

    // Преобразуем выбранную дату в строку формата "YYYY-MM-DD"
    const selectedMonth = month + 1; // Учитываем нумерацию месяцев с 0
    const selectedDateString = `${year}-${String(selectedMonth).padStart(2, '0')}-${String(day).padStart(2, '0')}`;

    // Фильтруем записи по выбранной дате
    const filteredRecords = recordStore.recordData.filter(record => {
        const recordDate = record.date.split(' ')[0];
        return recordDate === selectedDateString;
    });

    filteredRecords.sort((a, b) => {
        const timeA = a.date.split(' ')[1];
        const timeB = b.date.split(' ')[1];
        return timeA.localeCompare(timeB);
    });

    const waitingAndCompletedRecords = filteredRecords.filter(record =>
        record.status === 'WAITING' || record.status === 'COMPLETED'
    );

    const canceledRecords = filteredRecords.filter(record =>
        record.status === 'CANCELED'
    );

    return (
        <div>
            {filteredRecords.length > 0 ? (
                <div>
                    {waitingAndCompletedRecords.length > 0 && (
                        waitingAndCompletedRecords.map((record, index) => (
                            <div key={record.id}>
                                <RecordItem

                                    record={record}
                                    nextRecord={waitingAndCompletedRecords[index + 1] || null}
                                    isCanceled={false}
                                />
                            </div>
                        ))
                    )}

                    {waitingAndCompletedRecords.length > 0 && canceledRecords.length > 0 && (
                        <RecordSeparator text={"Отмененные записи"} />
                    )}

                    {canceledRecords.length > 0 && (
                        canceledRecords.map((record, index) => (
                            <div key={record.id}>
                                <RecordItem

                                    record={record}
                                    nextRecord={canceledRecords[index + 1] || null}
                                    isCanceled={true}
                                />
                            </div>
                        ))
                    )}
                </div>
            ) : (
                <div className={cl.noRecordsContainer}>
                    <Body className={cl.noRecordsMessage}>Пока что нет записей</Body>
                    <Body className={cl.noRecordsMessage}>на эту дату</Body>
                </div>
            )}
        </div>
    );
});

export default RecordList;


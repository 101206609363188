import { HeadLineMedium, Subheadline2 } from "../../../textStyles/TextStyleComponents"
import cl from "./NameAndPhoneForCancel.module.css"

const NameAndPhoneForCancel = ({ name, phoneNumber }) => {


    return (
        <div className={cl.container}>
            <HeadLineMedium>{name}</HeadLineMedium>
            <Subheadline2 className={cl.phoneNumber}>{phoneNumber}</Subheadline2>
        </div>
    )
}

export default NameAndPhoneForCancel
import React from 'react';
import { Body, Caption2, HeadLineSemibold, Subheadline1 } from '../../../textStyles/TextStyleComponents';
import cl from './RecordDate.module.css';

const RecordDate = ({ date, duration }) => {
    const months = [
        "января", "февраля", "марта", "апреля", "мая", "июня", 
        "июля", "августа", "сентября", "октября", "ноября", "декабря"
    ];

    const daysOfWeek = [
        "Воскресенье", "Понедельник", "Вторник", "Среда", 
        "Четверг", "Пятница", "Суббота"
    ];

    // Разбираем строку date
    const [datePart, timePart] = date.split(' ');
    const [year, month, day] = datePart.split('-').map(Number);
    const [startHour, startMinute] = timePart.split(':').map(Number);

    // Вычисляем день недели
    const dayOfWeekIndex = new Date(year, month - 1, day).getDay();
    const dayOfWeek = daysOfWeek[dayOfWeekIndex];

    // Вычисляем время окончания
    const endMinutesTotal = startHour * 60 + startMinute + duration;
    const endHour = Math.floor(endMinutesTotal / 60);
    const endMinute = endMinutesTotal % 60;

    // Форматируем значения
    const startTime = `${startHour.toString().padStart(2, '0')}:${startMinute.toString().padStart(2, '0')}`;
    const endTime = `${endHour.toString().padStart(2, '0')}:${endMinute.toString().padStart(2, '0')}`;
    const monthName = months[month - 1];

    return (
        <div className={cl.container}>
            <div>
                <Body>{`${day} ${monthName}`}</Body>
                <Subheadline1 className={cl.hintText}>{dayOfWeek}</Subheadline1>
            </div>

            <div>
                <HeadLineSemibold>{startTime}</HeadLineSemibold>
                <Caption2 className={cl.hintText}>до {endTime}</Caption2>
            </div>
        </div>
    );
}

export default RecordDate;

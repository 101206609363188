// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RecordComment_commentContainer__nSipx {
    border-radius: 10px;
    background-color: var(--tg-theme-bg-color);
    margin-bottom: 10px;
    padding: 11px 16px;
    margin-top: 5px;
}

.RecordComment_footnote__72Hbc {
    color: var(--tg-theme-hint-color);
}`, "",{"version":3,"sources":["webpack://./src/components/recordPage/recordComment/RecordComment.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,0CAA0C;IAC1C,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,iCAAiC;AACrC","sourcesContent":[".commentContainer {\n    border-radius: 10px;\n    background-color: var(--tg-theme-bg-color);\n    margin-bottom: 10px;\n    padding: 11px 16px;\n    margin-top: 5px;\n}\n\n.footnote {\n    color: var(--tg-theme-hint-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"commentContainer": `RecordComment_commentContainer__nSipx`,
	"footnote": `RecordComment_footnote__72Hbc`
};
export default ___CSS_LOADER_EXPORT___;

import { Body, Footnote } from '../../../textStyles/TextStyleComponents'
import cl from './RecordComment.module.css'

const RecordComment = ({ comment }) => {


    return (
        <div>
            <Footnote className={cl.footnote}>Комментарий клиента</Footnote>
            <div className={cl.commentContainer}>
                <Body>{comment}</Body>
            </div>
        </div>
    )
}

export default RecordComment
import { Body } from '../../../textStyles/TextStyleComponents';
import cl from './RecordPhoneNumber.module.css'

const RecordPhoneNumber = ({ phoneNumber }) => {


    return (
        <div className={cl.container}>
            <Body>Номер телефона</Body>
            <Body>{phoneNumber}</Body>
        </div>
    );
};

export default RecordPhoneNumber
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NameAndPhoneForCancel_container__zQ9KQ {
    height: 60px;
    border-radius: 10px;
    background-color: var(--tg-theme-bg-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 16px;
    margin-bottom: 10px;
}

.NameAndPhoneForCancel_phoneNumber__PUdCs {
    color: var(--tg-theme-hint-color);
}`, "",{"version":3,"sources":["webpack://./src/components/cancelRecordPage/nameAndPhoneForCancel/NameAndPhoneForCancel.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mBAAmB;IACnB,0CAA0C;IAC1C,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,iCAAiC;AACrC","sourcesContent":[".container {\n    height: 60px;\n    border-radius: 10px;\n    background-color: var(--tg-theme-bg-color);\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    padding-left: 16px;\n    margin-bottom: 10px;\n}\n\n.phoneNumber {\n    color: var(--tg-theme-hint-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `NameAndPhoneForCancel_container__zQ9KQ`,
	"phoneNumber": `NameAndPhoneForCancel_phoneNumber__PUdCs`
};
export default ___CSS_LOADER_EXPORT___;

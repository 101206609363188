import { useParams, useNavigate } from "react-router-dom"
import { useEffect } from "react";
import recordStore from "../../store/RecordStore";
import { observer } from "mobx-react-lite";
import HeaderUniversal from "../../components/universalComponents/headerUniversal/HeaderUniversal";
import NameAndPhoneForCancel from "../../components/cancelRecordPage/nameAndPhoneForCancel/NameAndPhoneForCancel";
import { useState } from "react";
import CommentForCancel from "../../components/cancelRecordPage/commentForCancel/CommentForCancel";
import axios from "axios";
import ModalUniversal from "../../components/universalComponents/modalUniversal/ModalUniversal";

const CancelRecordPage = observer(() => {
    const { id } = useParams()
    const tg = window.Telegram.WebApp;
    const navigate = useNavigate()

    const [record, setRecord] = useState(recordStore.recordData.find(record => record.id === parseInt(id)));

    const [cancelComment, setCancelComment] = useState('');

    const [showErrorModal, setShowErrorModal] = useState(false);

    useEffect(() => {
        if (recordStore.recordData.length === 0) {
            navigate('*');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const handleMainButtonClick = async () => {
            tg.MainButton.disable();
            const botUsername = localStorage.getItem('botUsername')

            const patchData = {
                status: "CANCELED",
                actor: 'MASTER',
                cancelComment: cancelComment
            };

            try {
                const response = await axios.patch(`${process.env.REACT_APP_MAIN_API_URL}self-employed/${botUsername}/recordings/${id}`, patchData);
                console.log("Статус записи успешно обновлен", response.data);
                navigate('*');
            } catch (error) {
                setShowErrorModal(true);
                tg.MainButton.enable();
                if (error.response) {
                    // Сервер вернул ответ с кодом ошибки, который находится в пределах диапазона 2xx
                    const errorData = error.response.data;
                    console.error("Ошибка:", errorData.error);
                    console.error("Описание:", errorData.description);
                } else if (error.request) {
                    // Запрос был сделан, но ответа не было получено
                    console.error("Запрос был сделан, но ответа не было получено", error.request);
                } else {
                    // Произошла другая ошибка при настройке запроса
                    console.error("Ошибка:", error.message);
                }
            }
        }

        tg.MainButton.onClick(handleMainButtonClick);


        return () => {
            tg.MainButton.offClick(handleMainButtonClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cancelComment])

    useEffect(() => {
        const handleBackButtonClick = () => {
            navigate(`/recordPage/${id}`)
        }

        tg.MainButton.setText("Отменить");
        tg.MainButton.color = tg.themeParams.destructive_text_color;
        tg.MainButton.show();

        tg.BackButton.onClick(handleBackButtonClick);

        return () => {
            tg.BackButton.offClick(handleBackButtonClick);
            tg.MainButton.hide();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <HeaderUniversal>Отмена записи</HeaderUniversal>
            <NameAndPhoneForCancel name={record.name} phoneNumber={record.phone_number} />
            <CommentForCancel cancelComment={cancelComment} setCancelComment={setCancelComment} />

            {showErrorModal &&
                <ModalUniversal
                    text={'Не удалось отменить запись.\nПопробуйте снова'}
                    setIsVisible={setShowErrorModal}
                    cancelText={'Окей'}
                />
            }
        </div>
    )
})

export default CancelRecordPage
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Calendar_container__Da\\+3F {
    margin-top: -8px;
    height: 147px;
    background-color: var(--tg-theme-bg-color);
    /* background-color: gray; */
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 25px;
}

.Calendar_monthYearContainer__rqBxC {
    display: flex;
    align-items: center;
    
    /* padding-top: 20px;
    padding-bottom: 13px; */
    padding-bottom: 5px;
}

.Calendar_monthText__Qry9M {
    display: inline-block;
    margin-left: 20px;
}

.Calendar_yearText__MagXA {
    display: inline-block;
    margin-left: 10px;
}

.Calendar_btn__tG7N2 {
    margin-left: auto;
    margin-right: 13px;
    display: flex;
    color: var(--tg-theme-link-color);
    justify-content: space-between;
    align-items: center;
    border: none;
    background-color: var(--tg-theme-bg-color);
    height: 48px;
    text-decoration: none;
}

.Calendar_icon__\\+iVm8 {
    padding-right: 3.26px;
}`, "",{"version":3,"sources":["webpack://./src/components/recordListPage/calendar/Calendar.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,0CAA0C;IAC1C,4BAA4B;IAC5B,kBAAkB;IAClB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;;IAEnB;2BACuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;IACrB,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,aAAa;IACb,iCAAiC;IACjC,8BAA8B;IAC9B,mBAAmB;IACnB,YAAY;IACZ,0CAA0C;IAC1C,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".container {\n    margin-top: -8px;\n    height: 147px;\n    background-color: var(--tg-theme-bg-color);\n    /* background-color: gray; */\n    margin-left: -15px;\n    margin-right: -15px;\n    margin-bottom: 25px;\n}\n\n.monthYearContainer {\n    display: flex;\n    align-items: center;\n    \n    /* padding-top: 20px;\n    padding-bottom: 13px; */\n    padding-bottom: 5px;\n}\n\n.monthText {\n    display: inline-block;\n    margin-left: 20px;\n}\n\n.yearText {\n    display: inline-block;\n    margin-left: 10px;\n}\n\n.btn {\n    margin-left: auto;\n    margin-right: 13px;\n    display: flex;\n    color: var(--tg-theme-link-color);\n    justify-content: space-between;\n    align-items: center;\n    border: none;\n    background-color: var(--tg-theme-bg-color);\n    height: 48px;\n    text-decoration: none;\n}\n\n.icon {\n    padding-right: 3.26px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Calendar_container__Da+3F`,
	"monthYearContainer": `Calendar_monthYearContainer__rqBxC`,
	"monthText": `Calendar_monthText__Qry9M`,
	"yearText": `Calendar_yearText__MagXA`,
	"btn": `Calendar_btn__tG7N2`,
	"icon": `Calendar_icon__+iVm8`
};
export default ___CSS_LOADER_EXPORT___;
